<script setup lang="ts">
import type { AddablePlugin } from '~/stores/plugins'
import TheMenu from '~/components/TheMenu.vue'

defineProps({
  company: {
    type: Object,
    default: () => ({})
  }
})

const { $linker } = useNuxtApp()
const route = useRoute()
const pluginsStore = usePluginsStore()

const isShowMenu = ref(false)
const current = computed(() => {
  const { path } = route.matched[0]
  return (
    pluginsStore.myPlugins.find(({ id }) => {
      return id === 'interest' && /^\/performance-survey\/.*/.test(route.path)
        ? true
        : path.includes(`/${id}`)
    }) || {
      id: 'rest',
      path: '/',
      type: 'Main',
      icon: 'Main',
      name: '플러그인 메뉴'
    }
  )
})

// Created
;(async function init() {
  await pluginsStore.getMyPlugins()
})()

function moveTo(plugin: AddablePlugin) {
  const { blank, path } = plugin
  if (blank) {
    window.location.assign(path)
  } else {
    $linker.push(path)
  }
  isShowMenu.value = !isShowMenu.value
}
</script>

<template>
  <TheMenu
    v-model="isShowMenu"
    :y="12">
    <template #activator="{ on }">
      <div
        class="current-plugin"
        v-on="on">
        <span
          :class="{ [current.id]: true }"
          class="name">
          {{ current.name }}
        </span>
        <div class="flex-space"></div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18 9L12 15L6 9"
            stroke="#DADAEE"
            stroke-width="2" />
        </svg>
      </div>
    </template>
    <div class="plugins">
      <template
        v-for="(plugin, index) in pluginsStore.myPlugins"
        :key="plugin.id">
        <template v-if="index === 3">
          <div
            :key="plugin.id + '-division'"
            class="division"></div>
          <h4 :key="plugin.id + '-heading'">plug-in</h4>
        </template>
        <div
          :class="{ active: plugin.id === current.id }"
          class="plugin"
          @click="moveTo(plugin)">
          {{ plugin.name }}
        </div>
      </template>
    </div>
  </TheMenu>
</template>

<style lang="scss" scoped>
.current-plugin {
  display: flex;
  align-items: center;
  width: 200px;
  height: 32px;
  padding: 0 12px 0 16px;
  border-radius: 100px;
  background-color: #3e446e;
  color: #dadaee;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 400px) {
    width: auto;
  }
  img {
    margin-right: 4px;
    width: 20px;
  }
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.rest {
      padding-left: 2px;
      color: #9397bf;
    }
  }
  .flex-space {
    flex-grow: 1;
  }
}
.plugins {
  width: 200px;
  padding: 8px 0;
  border-radius: 8px;
  box-shadow:
    0 8px 10px rgba(0, 0, 0, 0.02),
    0 16px 22px rgba(0, 0, 0, 0.06);
  background-color: #3e446e;
  color: #dadaee;
  h4 {
    font-weight: 600;
    font-size: 12px;
    color: #656a98;
    padding: 10px 12px 4px;
  }
  .division {
    height: 1px;
    background-color: #333655;
  }
  .plugin {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 16px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &.active {
      background-color: #2b2d45;
    }
    &:hover {
      background-color: #333655;
    }
    img {
      margin-right: 4px;
      width: 20px;
    }
  }
}
</style>
