<script setup lang="ts">
import TheMenu from '~/components/TheMenu.vue'
import TheLoader from '~/components/TheLoader.vue'
import ThePlugins from '~/components/theHeader/ThePlugins.vue'
import LandingTypes from '~/components/theHeader/LandingTypes.vue'
import Notification from '~/components/theHeader/Notification.vue'
import MyCompanyMenu from '~/components/theHeader/MyCompanyMenu.vue'

const config = useRuntimeConfig()
const { $Parse, $linker, $user } = useNuxtApp()
const route = useRoute()
const userStore = useUserStore()

const currentCompany = ref()
const done = ref(false)
const isShow = ref(false)
const isLanding = computed(() => route.name === 'index')

// Created
;(async function init() {
  const [companies] = await Promise.all([
    $Parse.Cloud.run('FetchCompaniesOfUser'), // 사용자의 기업 가져오기
    userStore.getUser() // 사용자 가져오기
  ])
  currentCompany.value = companies[0] // 첫 번째 소유 기업만 사용!

  nextTick(() => {
    done.value = true
  })
})()

function signIn() {
  $linker.push(
    `/signin?returnUrl=${config.public.CLIENT_APP_URL}${route.fullPath}`
  )
}
function signInForLanding() {
  if ($Parse.User.current()) {
    $linker.push('/main')
    return
  }
  $linker.push(`/signin?returnUrl=${config.public.CLIENT_APP_URL}/main`)
}
async function signOut() {
  if ($Parse.User.current()) {
    try {
      await $user.logOut()
      window.location.reload()
    } catch (error) {
      console.log('로그아웃 에러:', error)
    }
  }
}
function offMenu() {
  isShow.value = false
}
</script>

<template>
  <div>
    <div class="header-margin"></div>
    <header>
      <div
        class="logo"
        @click="$router.push('/main')">
        <svg
          width="116"
          height="17"
          viewBox="0 0 116 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.3863 0H0.350101V3.48856H6.26548C6.55154 3.48856 6.71686 3.81186 6.54872 4.04247L0.932661 11.7448L0.379447 12.5041C0.13284 12.8419 0 13.249 0 13.6668V14.4194C0 15.4469 0.835976 16.2799 1.8672 16.2799H12.2535V12.7914H5.98804C5.70198 12.7914 5.53666 12.4681 5.7048 12.2375L11.7734 3.91453C12.0625 3.51794 12.2281 3.04626 12.2508 2.55828L12.2535 2.32571V1.86056C12.2535 0.833004 11.4175 0 10.3863 0ZM17.0382 0H21.7062V16.2799H17.0382V0ZM56.9497 0H61.6177V16.2799H56.9497V0ZM90.6761 0H86.008V16.2799H90.6761V8.75034L95.5833 16.2799H100.593L95.6553 8.70408C95.2407 8.068 95.2552 7.24485 95.6917 6.62358L100.346 0H95.6448L90.6761 7.07089V0ZM115.65 0H105.614C104.582 0 103.746 0.833004 103.746 1.86056L103.749 2.55828C103.772 3.04626 103.937 3.51794 104.227 3.91453L110.295 12.2375C110.463 12.4681 110.298 12.7914 110.012 12.7914H103.746V16.2799H114.133C115.164 16.2799 116 15.4469 116 14.4194V13.6668C116 13.249 115.867 12.8419 115.621 12.5041L115.067 11.7448L109.451 4.04247C109.283 3.81186 109.448 3.48856 109.735 3.48856H115.65V0ZM31.5091 0H26.841V14.4194C26.841 15.4469 27.677 16.2799 28.7083 16.2799H37.5775V12.7914H32.4427C31.927 12.7914 31.5091 12.3749 31.5091 11.8611V0ZM41.662 0H46.33V11.8611C46.33 12.3749 46.748 12.7914 47.2636 12.7914H52.3984V16.2799H43.5292C42.4979 16.2799 41.662 15.4469 41.662 14.4194V0ZM67.8028 0L70.1012 0.001966C70.9351 0.001966 71.7056 0.445247 72.1225 1.16482L76.4699 8.65743C76.5893 8.8635 76.9054 8.77909 76.9054 8.54115V0H80.8732V15.3497C80.8732 15.8634 80.4553 16.2799 79.9396 16.2799L77.6367 16.2807C76.8029 16.2807 76.0324 15.8374 75.6154 15.1179L71.2726 7.6225L71.2722 7.62184C71.1525 7.41662 70.837 7.5011 70.837 7.73879V16.2799H66.8692V0.930282C66.8692 0.416502 67.2872 0 67.8028 0Z"
            fill="#514EDA" />
        </svg>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.7597 6H8.4301V10.2857H15.6972C16.0486 10.2857 16.2517 10.6829 16.0451 10.9662L9.14578 20.4286L8.46615 21.3613C8.1632 21.7764 8 22.2765 8 22.7897V23.7143C8 24.9766 9.027 26 10.2939 26H23.0535V21.7143H15.3563C15.0049 21.7143 14.8018 21.3171 15.0084 21.0338L22.4636 10.809C22.8189 10.3218 23.0222 9.74235 23.0502 9.14286L23.0535 8.85714V8.28571C23.0535 7.02335 22.0265 6 20.7597 6Z"
            fill="#514EDA" />
        </svg>
      </div>

      <LandingTypes v-if="isLanding" />
      <ThePlugins
        v-else
        :company="currentCompany" />

      <div class="flex-space"></div>

      <div
        v-if="!isLanding && !userStore.loading"
        class="search"
        @click="$linker.push('/search')">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.8645 18.3208H19.0515L18.7633 18.0429C19.7719 16.8696 20.3791 15.3465 20.3791 13.6895C20.3791 9.99485 17.3842 7 13.6895 7C9.99485 7 7 9.99485 7 13.6895C7 17.3842 9.99485 20.3791 13.6895 20.3791C15.3465 20.3791 16.8696 19.7719 18.0429 18.7633L18.3208 19.0515V19.8645L23.4666 25L25 23.4666L19.8645 18.3208ZM13.6895 18.3208C11.1269 18.3208 9.05832 16.2521 9.05832 13.6895C9.05832 11.1269 11.1269 9.05832 13.6895 9.05832C16.2521 9.05832 18.3208 11.1269 18.3208 13.6895C18.3208 16.2521 16.2521 18.3208 13.6895 18.3208Z"
            fill="#DADAEE" />
        </svg>
      </div>

      <Notification v-if="!isLanding && done && userStore.hasUser" />

      <template v-if="isLanding">
        <div
          class="signin"
          @click="signInForLanding">
          로그인
        </div>
      </template>
      <template v-else>
        <TheLoader :loading="userStore.loading" />
        <template v-if="!userStore.loading && done && userStore.hasUser">
          <TheMenu
            v-model="isShow"
            :y="12"
            right>
            <template #activator="{ on }">
              <div
                v-if="userStore.user.profileImg"
                :style="{
                  backgroundImage: `url(${userStore.user.profileImg})`
                }"
                class="user"
                v-on="on"></div>
              <div
                v-else
                class="user"
                v-on="on">
                <img
                  src="~/assets/images/user/profile-bg.png"
                  alt="User's Profile Image" />
              </div>
            </template>
            <div class="user__menu">
              <div
                v-if="userStore.user.profileImg"
                :style="{
                  backgroundImage: `url(${userStore.user.profileImg})`
                }"
                class="profile"></div>
              <div
                v-else
                class="profile">
                <img
                  src="~/assets/images/user/profile-bg.png"
                  alt="User's Profile Image" />
              </div>
              <div class="user__name">
                {{ userStore.user.displayName }}
              </div>

              <!--보유한 기업이 있거나 없는 경우-->
              <MyCompanyMenu
                v-if="currentCompany"
                :my-company="currentCompany"
                @click:menu="offMenu" />
              <TheLink
                v-else
                to="/create-company"
                class="connect-company"
                @click="offMenu">
                <svg
                  width="12"
                  height="12"
                  style="margin-right: 6px"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 0V12"
                    stroke="#DADAEE"
                    stroke-width="2" />
                  <path
                    d="M12 6L-1.78814e-07 6"
                    stroke="#DADAEE"
                    stroke-width="2" />
                </svg>
                기업 연결하기
              </TheLink>

              <div
                class="default-menu"
                @click="offMenu">
                <TheLink to="/myaccount/profile"> 계정 관리 </TheLink>
                <TheLink to="/notification/news"> 알림 설정 </TheLink>
                <a @click="signOut">로그 아웃</a>
              </div>
            </div>
          </TheMenu>
        </template>
        <template v-else-if="!userStore.loading">
          <div
            class="signin"
            @click="signIn">
            로그인
          </div>
        </template>
      </template>
    </header>
  </div>
</template>

<style scoped lang="scss">
.header-margin {
  height: 64px;
}
svg {
  display: block;
}
header {
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 26px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 97;
  background-color: #222437;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
  .logo {
    cursor: pointer;
    margin-right: 16px;
    @media (max-width: 600px) {
      margin-right: 8px;
    }
    svg {
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: none;
      }
      @media (max-width: 600px) {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
  .flex-space {
    flex-grow: 1;
  }
  .search {
    margin: 0 8px;
    cursor: pointer;
  }
  .signin {
    flex-shrink: 0;
    padding: 9px 16px;
    border-radius: 100px;
    background-color: #2b2d45;
    color: #dadaee;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  .user {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    border: 1px solid #4c527f;
    border-radius: 50%;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

// DROP-DOWN MENU
.user__menu {
  width: 232px;
  padding: 24px 16px;
  border-radius: 16px;
  box-shadow:
    0 8px 10px rgba(0, 0, 0, 0.02),
    0 16px 22px rgba(0, 0, 0, 0.06);
  background-color: #2b2d45;
  color: #fff;
  font-size: 14px;
  .profile {
    width: 64px;
    height: 64px;
    margin: 0 auto;
    border: 1px solid #4c527f;
    border-radius: 50%;
    background-size: cover;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .user__name {
    margin: 6px 0 24px;
    font-weight: 600;
    line-height: 1.75;
    text-align: center;
  }
  .connect-company {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 13px 0;
    border-radius: 16px;
    background-color: #3e446e;
    font-weight: 600;
    color: #dadaee;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background-color: lighten(#3e446e, 5%);
    }
  }
  .default-menu {
    a {
      display: block;
      padding: 8px 16px;
      color: #b7bad7;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #dadaee;
      }
    }
  }
}
</style>
