<script setup lang="ts">
const props = defineProps({
  myCompany: {
    type: Object,
    default: () => ({})
  }
})
defineEmits(['click:menu'])

const { $Parse, $linker } = useNuxtApp()
const route = useRoute()

// console.log(props.myCompany);

const menuOfCompany = ref([
  {
    name: '기업 페이지',
    isActive: (companyId: string) =>
      new RegExp(`^(?:/company/${companyId}).*`).test(route.path), // 현재 주소와 일치하는가?
    action: (companyId: string) => {
      setMyCurrentCompanyId(companyId)
      $linker.push(`/company/${companyId}/`)
    }
  },
  {
    name: '데이터 통합 관리',
    isActive: (companyId: string) =>
      new RegExp(`^(?:/data-integration/${companyId}).*`).test(route.path),
    action: (companyId: string) => {
      setMyCurrentCompanyId(companyId)
      $linker.push(`/data-integration/${companyId}`)
    }
  },
  {
    name: '정보 동의서',
    isActive: () =>
      new RegExp('^(?:/information-agreement-form).*').test(route.path),
    action: (companyId: string) => {
      setMyCurrentCompanyId(companyId)
      $linker.push('/information-agreement-form')
    }
  },
  {
    name: '기업 정보 수정',
    isActive: (companyId: string) =>
      new RegExp(`^(?:/editor/company/${companyId}).*`).test(route.path),
    action: (companyId: string) => {
      setMyCurrentCompanyId(companyId)
      $linker.push(`/editor/company/${companyId}/defaultinfo`)
    }
  },
  {
    name: '관리자',
    isActive: (companyId: string) =>
      new RegExp(`^(?:/editor/member/${companyId}).*`).test(route.path),
    action: (companyId: string) => {
      setMyCurrentCompanyId(companyId)
      $linker.push(`/editor/member/${companyId}`)
    }
  }
])

function setMyCurrentCompanyId(companyId: string) {
  $Parse.Cloud.run('UpdateCurrentCompanyForUser', { companyId })
  window.localStorage.setItem('myCurrentCompanyId', companyId)
}
</script>

<template>
  <div class="my-company-menu">
    <div class="company-profile">
      <div
        :style="{ backgroundImage: `url(${myCompany.logo})` }"
        class="logo"></div>
      <div class="name">
        {{ myCompany.name }}
      </div>
    </div>
    <div
      class="company-menu"
      @click="$emit('click:menu')">
      <div
        v-for="menu in menuOfCompany"
        :key="menu.name"
        :class="{ active: menu.isActive(myCompany.id) }"
        class="menu-item"
        @click="menu.action(myCompany.id)">
        {{ menu.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.my-company-menu {
  margin-bottom: 16px;
  padding: 24px 20px 16px;
  border-radius: 16px;
  background-color: #3e446e;
  .company-profile {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #656a98;
    .logo {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #fff;
      background-image: url('/images/profile-bg-company.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 74%;
    }
    .name {
      line-height: 1.2;
      font-weight: 600;
      color: #dadaee;
    }
  }
  .company-menu {
    .menu-item {
      padding: 8px 0;
      color: #b7bad7;
      cursor: pointer;
      &:hover {
        color: #dadaee;
      }
      &.active {
        color: #dadaee;
      }
    }
  }
}
</style>
