import { defineStore } from 'pinia'

type User = {
  displayName: string
  email: string
  id: string
  profileImg: string
  sns: string[]
  currentCompany: {
    className: 'Company'
    id: string
  }
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {} as Partial<User>,
    loading: true
  }),
  getters: {
    hasUser(): boolean {
      return !!this.user.email
    }
  },
  actions: {
    async getUser() {
      try {
        const { $Parse } = useNuxtApp()
        this.user = await $Parse.Cloud.run('GetTheLatestCurrentUserData')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
})
