<script setup lang="ts">
import dayjs from 'dayjs'
import kebabCase from 'lodash/kebabCase'
import TheMenu from '~/components/TheMenu.vue'

interface NotificationsInfo {
  newNotiCount: number
  notiList: Notification[]
}
interface Notification {
  notiType: string // 알림 유형
  notificationId: string // 알림 ID
  createdAt: Date // 알림 생성 시간
  checkedAt?: Date // 사용자가 알림을 확인한 시간
  isChecked?: boolean // 사용자 알림 확인 여부
  message: string // 알림 메시지
  pluginState?: {
    type: string // 플러그인 유형
    added: string // 플러그인 추가 여부
  }
  // pluginType?: string // 알림 클릭시, 접근할 플러그인 유형
  // addedPlugin?: string // 플러그인 추가 여부
}

const { $Parse, $linker } = useNuxtApp()

const notificationCount = ref(0)
const notifications = ref<Notification[]>([])
const isShowMenu = ref(false)
const isShowModal = ref(false)
const scrollEl = ref<HTMLElement | null>(null)
const notiItems = ref<HTMLElement[]>([])

onMounted(() => {
  getNotifications()
})

async function getNotifications() {
  const { newNotiCount, notiList }: NotificationsInfo =
    await $Parse.Cloud.run('GetNotification')
  notificationCount.value = newNotiCount
  notifications.value = notiList
}
function initIO() {
  const io = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target instanceof HTMLElement) {
          const target = entry.target
          const foundItem = notifications.value.find(
            item => item.notificationId === target.dataset.notiId
          )
          if (foundItem) checkNotification(foundItem)
        }
      })
    },
    {
      root: scrollEl.value,
      threshold: 1
    }
  )
  notiItems.value.forEach(item => {
    io.observe(item)
  })
}
function toggleModal() {
  isShowModal.value = !isShowModal.value
  if (isShowModal.value) {
    setTimeout(() => {
      initIO()
    })
  }
}
async function checkNotification(item: Notification) {
  if (!item.isChecked && notificationCount.value > 0) {
    await $Parse.Cloud.run('UpdateNotification', {
      notiList: [item]
    })
    notificationCount.value -= 1
    item.isChecked = true
  }
}
function moveToNewsWritingPage(item: Notification) {
  if (item.pluginState?.added) {
    $linker.push(
      `/${kebabCase(item.pluginState.type)}?id=${item.notificationId}`
    )
  } else {
    $linker.push(`/plugins/${item.pluginState?.type}`)
  }
}
</script>

<template>
  <TheMenu
    v-model="isShowMenu"
    :y="12"
    right>
    <template #activator="{ on }">
      <div
        class="notification"
        v-on="on"
        @click="toggleModal">
        <span class="material-symbols-outlined"> notifications </span>
        <span
          v-if="notificationCount"
          class="__count">
          {{ notificationCount }}
        </span>
      </div>
    </template>
    <template #default>
      <div class="noti-pop-container">
        <div class="__title">알림</div>
        <div
          v-if="notifications.length"
          ref="scrollEl"
          class="items">
          <div
            v-for="item in notifications"
            :key="item.notificationId"
            ref="notiItems"
            :data-noti-id="item.notificationId"
            :class="{ checked: item.isChecked }"
            class="__item">
            <div class="__message">{{ item.message }}</div>
            <div class="__date">
              {{ dayjs(item.createdAt).format('YYYY-MM-DD') }}
            </div>
            <div
              class="__btn"
              @click="moveToNewsWritingPage(item)">
              언론보도 작성하기
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-noti-message">
          새로운 알림이 없습니다.
        </div>
        <div class="__end"></div>
      </div>
    </template>
  </TheMenu>
</template>

<style scoped lang="scss">
.notification {
  margin: 0 8px;
  color: #fff;
  position: relative;
  cursor: pointer;
  .material-symbols-outlined {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__count {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: #ff3b30;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.noti-pop-container {
  max-width: 400px;
  min-width: 300px;
  // padding: 24px 16px;
  border-radius: 16px;
  box-shadow:
    0 8px 10px rgba(0, 0, 0, 0.02),
    0 16px 22px rgba(0, 0, 0, 0.06);
  background-color: #2b2d45;
  color: #fff;
  font-size: 14px;
  .__title {
    padding: 10px 0;
    border-bottom: 1px solid #4c527f;
    text-align: center;
    font-weight: 600;
  }
  .__end {
    height: 20px;
    border-top: 1px solid #4c527f;
  }
  .items {
    max-height: 390px;
    overflow-y: auto;
  }
  .__item {
    padding: 14px 24px;
    border-top: 1px solid #4c527f;
    line-height: 1.3;
    background-color: lighten(#2b2d45, 5%);
    &.checked {
      background-color: #2b2d45;
    }
    &:first-child {
      border-top: none;
    }
    .__date {
      margin-top: 6px;
    }
    .__btn {
      width: max-content;
      height: 26px;
      margin: 6px auto 0;
      padding: 0 40px;
      border-radius: 100px;
      background-color: #3e446e;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: lighten(#3e446e, 10%);
      }
    }
  }
  .no-noti-message {
    padding: 20px 0;
    text-align: center;
    color: rgba(#fff, 0.5);
  }
}
</style>
