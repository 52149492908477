<script setup lang="ts">
const landingStore = useLandingStore()

const type = computed(() => {
  return landingStore.type
})
</script>

<template>
  <div class="landing-types">
    <div
      :class="{ active: type === 'institution' }"
      class="type"
      @click="landingStore.type = 'institution'">
      창업지원기관
    </div>
    <div
      :class="{ active: type === 'company' }"
      class="type"
      @click="landingStore.type = 'company'">
      스타트업
    </div>
  </div>
</template>

<style lang="scss" scoped>
.landing-types {
  width: 272px;
  padding: 4px;
  border-radius: 100px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #1d1e30;
  display: flex;
  gap: 8px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  .type {
    flex-grow: 1;
    height: 32px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: #999999;
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: #3948d9;
    }
  }
}
</style>
